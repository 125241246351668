import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortName',
})
export class ShortNamePipe implements PipeTransform {
  transform(fullName: string): any {
    if (fullName != null) {
      var space = fullName.includes(' ');
      var name = fullName.split(' ');
      if (space) {
        return name[0].charAt(0) + name[1].charAt(0);
      } else {
        return name[0].charAt(0);
      }
    }
  }
}

<footer>
    <div class="footer-top">

        <div class="one">
            <div class="row">
                <h3 class="text-center">Get in touch</h3>
                <div class="col-md-4 bg-gray ">
                    <div class="two">
                        <h4>
                            <i class="bi bi-phone" style="color: #d65f10;font-size: 25px;"></i>
                            <a href="tel:+923054948160">+923054948160</a>

                        </h4>
                    </div>
                </div>
                <div class="col-md-4 ">
                    <div class="two">
                        <h4 class="hfour">
                            <i class="bi bi-envelope" style="color: #d65f10;font-size: 25px;"></i>
                            <span>info@codinghubpro.com</span>
                        </h4>
                    </div>
                </div>
                <div class="col-md-4 bg-gray">
                    <div class="two">
                        <div class="btn btn-default" routerLink="/contact">
                            Contact Us Now
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div id="footer">
        <div class="container-fluid">

            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12">

                    <ul>
                        <li>
                            <a routerLinkActive="active" routerLink="services">
                                <i class="gg-chevron-double-right"></i> Services
                            </a>
                        </li>
                        <li>
                            <a routerLinkActive="active" href="https://courses.codinghub.pk" target="_blank">
                                <i class="gg-chevron-double-right"></i> Trainings
                            </a>
                        </li>
                    </ul>

                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">

                    <ul>
                        <li>
                            <a routerLinkActive="active" routerLink="contact">
                                <i class="gg-chevron-double-right"></i> Contact
                            </a>
                        </li>
                        <li>
                            <a routerLinkActive="active" routerLink="about">
                                <i class="gg-chevron-double-right"></i> About
                            </a>
                        </li>
                    </ul>

                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <p>
                        <i class="bi bi-map-marker" aria-hidden="true"></i> Address :
                        <br> Shahab Town,Street No-2,Near Ada Iqbal-Nagar, Rahim Yar Khan
                    </p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 text-center">
                    <p>
                        <a href="tel:+923058444160">+923058444160</a>
                        <br>
                        <i class="bi bi-caret-phone"></i> Email:admin@codinghubpro.com
                    </p>
                    <div class="social text-center">
                        <a href="https://www.facebook.com/CodingHub-854433864905046/ " target="_blank">
                            <i class="bi bi-facebook"></i>
                        </a>
                        <a href="tel:+923058444160">
                            <i class="bi bi-whatsapp"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="footer-end p-2">
                <p>
                    &#169; Copyright 2021 - All Rights Reserved.
                </p>
            </div>
        </div>

    </div>

</footer>
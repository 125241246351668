import { Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Product } from './../model/product.model';
import { ProcessHTTPMsgService } from '../auth/process-httpmsg.service';
import { Cart } from '../model/cart.model';
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root',
})
export class CartService {
  cart: any[] = [];
  offline: any[] = [];
  AppUser;
  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHTTPMsgService,
    @Inject('baseURL') private baseURL,
    private router: Router,
    private auth: AuthService
  ) {}

  taosterNotify() {
    var toasts = document.getElementById('toasts');

    toasts.style.padding = '15px';
    var notif = document.createElement('div');
    var y = document.createTextNode('valuellkll ' + ' !');
    notif.appendChild(y);
    toasts.appendChild(notif);
    setTimeout(() => {
      notif.remove();
      toasts.style.padding = '0px';
    }, 1000);
  }
  allCart;
  itemCount: Subject<number> = new Subject<number>();
  getCartTotal() {
    if (this.auth.isLoggedIn()) {
      return this.http
        .get(this.baseURL + 'cart')
        .pipe(catchError(this.processHTTPMsgService.handleError))
        .subscribe((data: any) => {
          if (data) {
            var total;
            data[0].orderItems.forEach((element) => {
              this.count += element.quantity;
            });
            this.itemCount.next(this.count);
            console.log(this.count);

            console.log('return: ', data[0].orderItems);
          }
        });
    } else {
      document.getElementById('loginModel').click();
    }
  }
  count = 0;

  increment(qty) {
    this.count = this.count + qty;
    this.itemCount.next(this.count);
  }
  decrement(qty) {
    this.count = this.count - qty;
    this.itemCount.next(this.count);
  }
  cartPost(product) {
    if (this.auth.isLoggedIn()) {
      var menuOverlay = document.querySelector('.update');
      menuOverlay.classList.toggle('active');
      console.log(product);
      // this.taosterNotify();
      return this.http
        .post(this.baseURL + 'cart', product)
        .pipe(catchError(this.processHTTPMsgService.handleError))
        .subscribe((data: any) => {
          if (data) {
            console.log('return: ', data);
            this.increment(1);
            menuOverlay.classList.remove('active');
          }
        });
    } else {
      document.getElementById('loginModel').click();
    }
  }

  detailCartPost(product, qty) {
    if (this.auth.isLoggedIn()) {
      var menuOverlay = document.querySelector('.update');
      menuOverlay.classList.toggle('active');
      console.log(product);

      return this.http
        .post(this.baseURL + 'cart', product)
        .pipe(catchError(this.processHTTPMsgService.handleError))
        .subscribe((data: any) => {
          if (data) {
            console.log('return: ', data);
            this.increment(qty);
            menuOverlay.classList.remove('active');
          }
        });
    } else {
      document.getElementById('loginModel').click();
    }
  }

  addQuantity(product) {
    return this.http
      .post(this.baseURL + 'cart', product)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getCart() {
    return this.http
      .get(this.baseURL + 'cart')
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getOneItem(id: string): Observable<Product> {
    return this.http
      .get<Product>(this.baseURL + 'cart/' + id)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  deleteByIdCart(id: string) {
    return this.http
      .delete(this.baseURL + 'cart/' + id)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
}

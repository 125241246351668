import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './auth/auth-guard.service';
import { SignupgaurdService } from './auth/signupgaurd.service';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'admin/addabout',

    loadChildren: () =>
      import('./admin/addabout/addabout.module').then((m) => m.AddaboutModule),
  },
  {
    path: 'admin/addabout/:id',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./admin/addabout/addabout.module').then((m) => m.AddaboutModule),
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('./projects/projects.module').then((m) => m.ProjectsModule),
  },
  {
    path: 'project-detail/:id',
    loadChildren: () =>
      import('./projects/project-detail/project-detail.module').then((m) => m.ProjectDetailModule),
  },
  {
    path: 'admin/dashboard',
    loadChildren: () =>
      import('./admin/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin/messages',
    loadChildren: () =>
      import('./admin/messages/messages.module').then((m) => m.MessagesModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin/clients',
    loadChildren: () =>
      import('./admin/clients/clients.module').then((m) => m.ClientsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin/add-client/:id',
    loadChildren: () =>
      import('./admin/add-client/add-client.module').then(
        (m) => m.AddClientModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin/add-client',
    loadChildren: () =>
      import('./admin/add-client/add-client.module').then(
        (m) => m.AddClientModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin/add-case/:id',
    loadChildren: () =>
      import('./admin/add-case/add-case.module').then((m) => m.AddCaseModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin/add-case',

    loadChildren: () =>
      import('./admin/add-case/add-case.module').then((m) => m.AddCaseModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./admin/signup/signup.module').then((m) => m.SignupModule),
    canActivate: [SignupgaurdService],
  },
  {
    path: 'admin/all-projects',
    loadChildren: () =>
      import('./admin/all-projects/all-projects.module').then(
        (m) => m.AllProjectsModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin/add-project/:id',
    loadChildren: () =>
      import('./admin/add-products/add-products.module').then(
        (m) => m.AddProductsModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin/add-project',
    loadChildren: () =>
      import('./admin/add-products/add-products.module').then(
        (m) => m.AddProductsModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin/add-service',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./admin/add-services/add-services.module').then(
        (m) => m.AddServicesModule
      ),
  },
  {
    path: 'admin/add-service/:id',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./admin/add-services/add-services.module').then(
        (m) => m.AddServicesModule
      ),
  },
  {
    path: 'admin/all-services',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./admin/all-services/all-services.module').then(
        (m) => m.AllServicesModule
      ),
  },
  {
    path: 'services',
    loadChildren: () =>
      import('./allservices/services.module').then((m) => m.ServicesModule),
  },
  {
    path: 'detail/:id',
    loadChildren: () =>
      import('./detail/detail.module').then((m) => m.DetailModule),
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

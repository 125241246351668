import { CategoryService } from './../services/category.service';
import { CartService } from 'src/app/services/cart.service';
import { ProductService } from './../services/product.service';

import { Inject, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    '(window:scroll)': 'updateHeader($event)',
  },
})
export class HeaderComponent implements OnInit {
  isScrolled = false;
  currPos: Number = 0;
  startPos: Number = 0;
  changePos: Number = 90;
  loginForm: FormGroup;
  bool = true;
  AppUser;
  err;

  users;
  username: string = undefined;
  subscription: Subscription;

  constructor(
    @Inject('baseImageURL') private baseImageURL,
    private pro: ProductService,
    private auth: AuthService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.createLogin();
    this.loginCredin();
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        var check = document
          .getElementById('navbar')
          .classList.contains('show');
        if (check) {
          this.closeNavbar();
        }
        window.scrollTo(0, 0);
      }
    });
  }

  closeNavbar() {
    document.getElementById('toggler').click();
  }
  createLogin() {
    this.loginForm = this.fb.group({
      username: [''],
      password: ['', Validators.required],
    });
  }

  updateHeader(evt) {
    this.currPos =
      (window.pageYOffset || evt.target.scrollTop) -
      (evt.target.clientTop || 0);
    if (this.currPos >= this.changePos) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }

  loginCredin() {
    this.auth.loadUserCredentials();
    this.auth.getUsername().subscribe((name) => {
      this.AppUser = name;
     
    });
  }

  login() {
    var user = this.loginForm.value;

    this.auth.logIn(user);
  }

  logout() {
    this.auth.logOut();
    this.router.navigate(['/home']);
  }

  goTo(id) {
    this.router.navigate(['/detail/' + id]);
  }
}

import { Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ProcessHTTPMsgService } from '../auth/process-httpmsg.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHTTPMsgService,
    @Inject('baseURL') private baseURL,
    private router: Router
  ) {}

  getSearchPro(name): Observable<any> {
    return this.http
      .get<any>(this.baseURL + 'product/search?name=' + name)
      .pipe(map((pro) => pro))
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  getSearchProOnInput(name): Observable<any> {
    return this.http
      .get<any>(this.baseURL + 'product/filter/search?name=' + name)
      .pipe(map((pro) => pro))
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getAllProjects(): Observable<any> {
    return this.http
      .get<any>(this.baseURL + 'project')
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  createProject(pro: any) {
    console.log('post call with: ', pro);
    return this.http
      .post(this.baseURL + 'project', pro)
      .pipe(catchError(this.processHTTPMsgService.handleError))
      .subscribe((data) => {
        this.router.navigate(['/admin/all-projects']);
        console.log('return: ', data);
      });
  }

  updatePro(pro: any, id) {
    console.log('dell with headers: ');
    return this.http
      .put(this.baseURL + 'project/' + id, pro)
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      )
      .subscribe((data) => {
        this.router.navigate(['/admin/all-projects']);
        console.log('return: ', data);
      });
  }
  getPro(id: string): Observable<any> {
    return this.http
      .get<any>(this.baseURL + 'project/' + id)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  deletePro(id: string) {
    console.log('dell with headers: ');
    return this.http
      .delete(this.baseURL + 'project/' + id)
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }
  getHomeFeaturePro(): Observable<any> {
    return this.http
      .get<any>(this.baseURL + 'project?home=true')
      .pipe(map((pro) => pro))
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  postImage(image) {
    console.log('post call with: ', JSON.stringify(image));
    return this.http
      .post(this.baseURL + 'imageUpload', image)
      .pipe(catchError(this.processHTTPMsgService.handleError))
      .subscribe((data) => console.log('return: ', data));
  }

  deleteImg(image) {
    console.log('del call with: ', image);
    return this.http
      .delete(this.baseURL + 'imageUpload/' + image)
      .pipe(catchError(this.processHTTPMsgService.handleError))
      .subscribe((data) => console.log('return: ', data));
  }
}

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { baseURL } from '../model/baseurl';
import { ProcessHTTPMsgService } from '../auth/process-httpmsg.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHTTPMsgService,
    @Inject('baseURL') private baseURL
  ) {}

  getHomeProjects() {
    return this.http
      .get<any[]>(this.baseURL + 'project?home=true')
      .pipe(map((doctors) => doctors))
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  getHomeServices() {
    return this.http
      .get<any[]>(this.baseURL + 'service?home=true')
      .pipe(map((doctors) => doctors))
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getHomeClients() {
    return this.http
      .get<any[]>(this.baseURL + 'client?home=true')
      .pipe(map((doctors) => doctors))
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  getHomeCases() {
    return this.http
      .get<any[]>(this.baseURL + 'case?home=true')
      .pipe(map((doctors) => doctors))
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  getAbout() {
    return this.http
      .get<any[]>(this.baseURL + 'about')
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  createAbout(about: any) {
    console.log('post call with: ', JSON.stringify(about));
    return this.http
      .post(this.baseURL + 'about', about)
      .pipe(catchError(this.processHTTPMsgService.handleError))
      .subscribe((data) => console.log('return: ', data));
  }
  getOneAbout(id: string) {
    return this.http
      .get<any>(this.baseURL + 'about/' + id)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  updateAbout(id, about: any) {
    console.log('dell with headers: ');
    return this.http
      .put(this.baseURL + 'about/' + id, about)
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      )
      .subscribe((data) => console.log('return: ', data));
  }
  deleteAbout(id: string) {
    console.log('dell with headers: ');
    return this.http
      .delete(this.baseURL + 'about/' + id)
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      )
      .subscribe((data) => console.log('return: ', data));
  }
}

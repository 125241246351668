<div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
    <!-- <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="2" aria-label="Slide 3"></button> 
    </div> -->
    <div class="carousel-inner">
        <div class="carousel-item active ">

            <img src="../assets/images/item1.jpg" alt="image " style="width:100%; ">

            <div class="content">
                <div class="carousel-caption">
                    <h1 class=" wow  animated">
                        Welcome To The Codinghub!
                        <br> A Software Solution
                        <br> Provider
                    </h1>
                    <div class="btn btn-default wow fadeInRight animated " routerLink="/about">
                        Read More
                    </div>
                </div>

            </div>

        </div>
        <div class="carousel-item ">
            <img src="../assets/images/item2.jpg " alt="image" style="width:100%; ">
            <div class="content">
                <div class="carousel-caption">
                    <h1 class="wow  animated">
                        Get Innovative Customized
                        <br> And Optimized Software At Your On Ease
                    </h1>
                    <div class="btn btn-default  wow fadeInRight animated" routerLink="/services">
                        Explore
                    </div>
                </div>
            </div>
        </div>
        <div class="carousel-item">
            <img src="../assets/images/item3.jpg" alt="image" style="width:100%; ">
            <div class="content">
                <div class="carousel-caption">
                    <h1 class=" wow  animated">
                        Contact Now To Grow
                        <br> Your Business with Latest Technologies
                    </h1>
                    <div class="btn btn-default wow fadeInLeft animated" routerLink="/contact">
                        Contact Us
                    </div>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
    <div class="wave">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#273c6f" fill-opacity="1" d="M0,160L48,186.7C96,213,192,267,288,250.7C384,235,480,149,576,144C672,139,768,213,864,218.7C960,224,1056,160,1152,149.3C1248,139,1344,181,1392,202.7L1440,224L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
        </svg>
    </div>
</div>
<section>

    <div class="container-fluid">
        <div class="services">
            <!-- <div [hidden]="Services" class="text-center">
                <i class="fa fa-spinner fa-spin" style="font-size:45px;color:white"></i>
            </div> -->
            <!-- *ngFor="let data of Services" -->
            <div class="row">

                <div class="col-lg-4 col-md-6" data-wow-delay="0.3s">
                    <div class="webapp">
                        <h4 class="text-center">Mobile Application</h4>
                        <p>
                            With the advancement of new technology mobile phone and mobile apps is a necessary tool for everybody in the community. We
                            provide IOS, Android and re... With the advancement of new technology mobile phone and mobile
                            apps is a necessary tool for everybody in the community. We provide IOS, Android and re...
                        </p>
                        <div class="btn btn-default" [routerLink]="['/detail', 12]">
                            Learn More
                        </div>

                    </div>
                </div>
                <div class="col-lg-4 col-md-6" data-wow-delay="0.3s">
                    <div class="webapp">
                        <h4 class="text-center">Desktop Application</h4>
                        <p>
                            With the advancement of new technology mobile phone and mobile apps is a necessary tool for everybody in the community. We
                            provide IOS, Android and re... With the advancement of new technology mobile phone and mobile
                            apps is a necessary tool for everybody in the community. We provide IOS, Android and re...
                        </p>
                        <div class="btn btn-default" [routerLink]="['/detail', 12]">
                            Learn More
                        </div>

                    </div>
                </div>
                <div class="col-lg-4 col-md-6" data-wow-delay="0.3s">
                    <div class="webapp">
                        <h4 class="text-center">Ecommerace Sites</h4>
                        <p>
                            With the advancement of new technology mobile phone and mobile apps is a necessary tool for everybody in the community. We
                            provide IOS, Android and re... With the advancement of new technology mobile phone and mobile
                            apps is a necessary tool for everybody in the community. We provide IOS, Android and re...
                        </p>
                        <div class="btn btn-default" [routerLink]="['/detail', 12]">
                            Learn More
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <!-- <div [hidden]="Connectworld" align="center">
            <i class="fa fa-spinner fa-spin" style="font-size:45px"></i>
        </div> -->

            <div class="mission text-center">
                <h2>What We Do!</h2>

                <b>
                    . . . We Bring Your Ideas Into Reality . . .
                </b>
                <p>
                    Codinghub is a software development company that provide solutions to grow your business by transforming it to digital world.
                    Our competent and energetic team members give the best innovative ideas, suggestions and software for
                    your business. Your ideas with combine effort of our team will make your business successful.
                </p>
                <br>

            </div>

        </div>
    </div>

    <div class="service">
        <div class="top-curve">
            <!-- <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
                <path
                    d="M-126.69,-76.47 C127.82,289.63 435.38,-53.78 525.67,93.25 L564.05,156.41 L-19.47,154.44 Z"
                    style="stroke: none; fill: #d66845;"
                ></path>
            </svg> -->

            <div>
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
                    <path d="M-209.65,-35.03 C-166.76,176.14 53.33,-108.05 616.53,65.63 L548.81,257.06 L-63.48,203.78 Z" style="stroke: none; fill: #d66845;"></path>
                </svg>
            </div>
        </div>
        <div class="text-center">
            <h2>Services</h2>
        </div>

        <div class="container-fluid">
            <div class="text-center" [hidden]="services">

                <div class="loader"></div>

            </div>
            <div class="row">
                <owl-carousel-o [options]="serviceOptions2">
                    <ng-template carouselSlide *ngFor="let item of services">

                        <div class="card">
                            <!-- <i class="bi bi-window"></i> -->
                            <h4>{{item.name}}</h4>
                            <div class="detail" style="min-height:173px;" [innerHTML]="item.detail">

                            </div>
                            <!-- <p>{{item.detail | summary: 400}}</p> -->
                            <div class="text-center mt-2">
                                <div class="btn btn-default" [routerLink]="['/detail/', item._id]">
                                    Read more
                                </div>
                            </div>
                        </div>

                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>

    <div class="container-fluid mb-4">
        <div class="work">
            <h2 class="text-center">Recent Projects</h2>

            <div class="text-center" [hidden]="projects">

                <div class="loader"></div>

            </div>
            <div class="owl">
                <div class="text-end mb-2" *ngIf="projects">
                    <a class="btn btn-default" routerLink="/projects">See All Projects</a>
                </div>
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide *ngFor="let item of projects">
                        <div class="card ">
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <div class="text">
                                        <h1>{{item.name}}</h1>
                                        <div class="detail" [innerHTML]="item.detail">

                                        </div>
                                        <!-- <p>{{item.detail | summary: 550}}</p> -->
                                        <button class="btn btn-default mt-2" routerLink="/projects">
                                            Read More
                                        </button>
                                    </div>

                                </div>
                                <div class="col-md-6">
                                    <img src="{{ baseImageURL +item.image }}" alt="image">
                                </div>
                            </div>

                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>

    </div>
    <!-- <div class="case-study">

        <h2 class="text-center">Case Studies</h2>
        <div class="container-fluid">
            <div class="text-center" [hidden]="Cases">

                <div class="loader"></div>

            </div>
            <div class="study mt-4">

                <div class="row no-gutter">
                    <div class="col-lg-4 col-md-6 col-sm-12 box" *ngFor="let item of Cases">
                        <a class="work-box" [routerLink]="['/category/Men4']">
                            <img src="{{ baseImageURL +item.image }}" alt="image">
                            <div class="content">

                                <div class="overlay-caption">
                                    <h2>{{item.name}}</h2>
                                </div>
                            </div>
                          
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div> -->
    <div class="clients-section ">

        <div class="clients">

            <h2 class="text-center">Our Clients</h2>
            <div class="container-fluid w-8">
                <div class="text-center" [hidden]="clients">

                    <div class="loader"></div>

                </div>
                <div class="owl">
                    <owl-carousel-o [options]="customOptions2">
                        <ng-template carouselSlide *ngFor="let item of clients">
                            <div class="card">
                                <div class="text-center">
                                    <a href="{{item.link}}" target="_blank">
                                        <img src="{{ baseImageURL + item.image }}" alt="image">
                                    </a>
                                </div>

                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</section>

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  users: any;

  constructor(private authService:AuthService ) { }

  ngOnInit(): void {
      this.authService.getUsers()
      .subscribe(users=>{this.users = users, console.log(users);
      })
  }
  delUser(userId){
    console.log("dell call from tempaletes with id: ", userId);
    this.authService.deleteUser(userId)
  }

}

import { Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Project } from 'src/app/model/project.model';
import { ProcessHTTPMsgService } from '../auth/process-httpmsg.service';

@Injectable({
  providedIn: 'root',
})
export class CaseService {
  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHTTPMsgService,
    @Inject('baseURL') private baseURL,
    private router: Router
  ) {}
  getAllCase(): Observable<any> {
    return this.http
      .get<any>(this.baseURL + 'case')
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  createCase(pro) {
    console.log('post call with: ', pro);
    return this.http
      .post(this.baseURL + 'case', pro)
      .pipe(catchError(this.processHTTPMsgService.handleError))
      .subscribe((data) => {
        console.log('return: ', data);
        this.router.navigate(['/admin/projects']);
      });
  }

  updatePro(pro: Project, id) {
    console.log('update with headers: ');
    return this.http
      .put(this.baseURL + 'case/' + id, pro)
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      )
      .subscribe((data) => {
        console.log('return: ', data);
        this.router.navigate(['/admin/projects']);
      });
  }
  getPro(id: string): Observable<Project> {
    return this.http
      .get<Project>(this.baseURL + 'case/' + id)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  deletePro(id: string) {
    console.log('dell with headers: ');
    return this.http
      .delete(this.baseURL + 'case/' + id)
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }
  postImage(image) {
    console.log('post call with: ', JSON.stringify(image));
    return this.http
      .post(this.baseURL + 'imageUpload', image)
      .pipe(catchError(this.processHTTPMsgService.handleError))
      .subscribe((data) => console.log('return: ', data));
  }

  deleteImg(image) {
    console.log('del call with: ', image);
    return this.http
      .delete(this.baseURL + 'imageUpload/' + image)
      .pipe(catchError(this.processHTTPMsgService.handleError))
      .subscribe((data) => console.log('return: ', data));
  }
}

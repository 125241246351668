<header>
    <nav class="navbar navbar-expand-md fixed-top" [ngClass]="{'scrolled-header': isScrolled}">

        <a class="navbar-brand" routerLink="home">
            <img src="../assets/images/logo.png">
        </a>
        <button class="navbar-toggler" type="button" id="toggler" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbar">
            <ul class="navbar-nav ms-auto">

                <li class="nav-item">
                    <a class="nav-link" routerLink="home">
                        Home
                        <div class="border"></div>

                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="services">
                        Services
                        <div class="border"></div>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="about">
                        About
                        <div class="border"></div>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/contact']">
                        Contact
                        <div class="border"></div>
                    </a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" href="https://courses.codinghub.pk" target="_blank">
                        Trainings
                        <div class="border"></div>
                    </a>
                </li> -->

                <li class="nav-item" *ngIf="!AppUser;">
                    <a class="nav-link" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Login
                        <div class="border"></div>
                    </a>
                </li>

                <li class="dropdown" *ngIf="AppUser;">
                    <a class="nav-link dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ AppUser.username | uppercase |  shortName}}
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                        <li *ngIf="AppUser.admin" class="nav-item">
                            <a class="nav-link" [routerLink]="['admin/dashboard']">
                                Dashboard
                            </a>
                        </li>

                        <li (click)="logout()" class="nav-item">
                            <a class="nav-link">Logout</a>
                        </li>
                    </ul>

                </li>
                <li class="nav-item">
                    <a class="nav-link" href="tel:+923058444160" data-toggle="tooltip" data-placement="bottom" id="bg" title="+923058444160">
                        <i class="bi bi-telephone-outbound"></i> 
                        <div class="border"></div>
                    </a>
                </li>
            </ul>
            <!-- <form class="d-flex">
                    <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                    <button class="btn btn-outline-success" type="submit">
                        Search
                    </button>
                </form> -->

        </div>
    </nav>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="text-center"></div>
                <div class="modal-header ">
                    <h5 class="modal-title" id="exampleModalLabel">
                        Please Login to continue.
                    </h5>
                    <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="loginForm" (ngSubmit)="login()">
                        <div class="form-group mb-2">
                            <input type="text" formControlName="username" name="username" placeholder="Username" class="form-control">
                        </div>
                        <div class="form-group mb-2">
                            <input type="password" formControlName="password" name="password" placeholder="Password" class="form-control">
                        </div>
                        <div class="text-center mt-2">
                            <button class="btn btn-default" [disabled]="!loginForm.valid" type="submit" id="one">
                                Login
                            </button>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</header>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: "summary"
})
export class SummaryPipe implements PipeTransform {
    transform(value: string, limit?: any) {

        if (!value)
            return null;
            let limitcount = (limit) ? limit : 2000;
        return value.substr(0, limitcount) + '...';
    }
}
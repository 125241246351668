import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModule } from './app-routing.module'; // CLI imports
import { HttpClientModule } from '@angular/common/http';
import {
  AuthInterceptor,
  UnauthorizedInterceptor,
} from './auth/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProcessHTTPMsgService } from './auth/process-httpmsg.service';
import { UsersComponent } from './admin/users/users.component';
import { CommonModule } from '@angular/common';
import { environment } from './../environments/environment';
import { SummaryPipe } from './summary.pipe';
// Import the library
import { NgMagnizoomModule } from 'ng-magnizoom';
import { ShortNamePipe } from './header/name.pipe';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ShortNamePipe,
    SummaryPipe,
    HomeComponent,
    UsersComponent,
    
  ],
  imports: [
    NgMagnizoomModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    CarouselModule,
   
    LoadingBarRouterModule,
    BrowserModule,
    CommonModule,

    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    ProcessHTTPMsgService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    { provide: 'baseURL', useValue: environment.baseURL },
    { provide: 'baseImageURL', useValue: environment.baseImageURL },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Bootstrap } from 'bootstrap/dist/js/bootstrap';
import { CartService } from './../services/cart.service';
import { Product } from './../model/product.model';
import { ProductService } from './../services/product.service';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HomeService } from '../services/home.service';
import { CaseService } from '../services/case.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  featured;

  AppUser;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="bi bi-arrow-left-short"></i>',
      '<i class="bi bi-arrow-right-short"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
      1400: {
        items: 1,
      },
      1900: {
        items: 2,
      },
    },
    nav: true,
  };
  customOptions2: OwlOptions = {
    loop: true,
    // mouseDrag: true,
    // touchDrag: true,
    // pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="bi bi-arrow-left-short"></i>',
      '<i class="bi bi-arrow-right-short"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },

      600: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1400: {
        items: 3,
      },
      1900: {
        items: 4,
      },
    },
    nav: true,
  };

  serviceOptions2: OwlOptions = {
    loop: true,
    // mouseDrag: true,
    // touchDrag: true,
    // pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="bi bi-arrow-left-short"></i>',
      '<i class="bi bi-arrow-right-short"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },

      600: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1400: {
        items: 3,
      },
      1900: {
        items: 4,
      },
    },
    nav: true,
  };

  services;
  projects;
  clients;
  Cases;
  constructor(
    private pro: HomeService,
    @Inject('baseImageURL') private baseImageURL,
    private cart: CartService,
    private auth: AuthService,
    private caseS: CaseService
  ) {}

  ngOnInit(): void {
    this.auth.loadUserCredentials();
    this.auth.getUsername().subscribe((name) => {
      this.AppUser = name;
    });
    this.pro.getHomeClients().subscribe((pro) => {
      this.clients = pro;
    });
    this.pro.getHomeProjects().subscribe((pro) => {
      this.projects = pro;
    });
    this.pro.getHomeServices().subscribe((pro) => {
      this.services = pro;
    });
    // this.pro.getHomeCases().subscribe((pro) => (this.Cases = pro));
  }
}

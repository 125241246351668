import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ProcessHTTPMsgService } from './process-httpmsg.service';

interface AuthResponse {
  status: string;
  success: string;
  token: string;
}

interface JWTResponse {
  status: string;
  success: string;
  user: any;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  tokenKey = 'JWT';
  isAuthenticated: Boolean = false;
  username: Subject<string> = new Subject<string>();
  authToken: string = undefined;

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHTTPMsgService,
    @Inject('baseURL') private baseURL
  ) {}

  checkJWTtoken() {
    this.http.get<JWTResponse>(this.baseURL + 'users/checkJWTtoken').subscribe(
      (res) => {
        //console.log('JWT Token Valid: ', res);
        this.sendUsername(res.user);
      },
      (err) => {
        // console.log('JWT Token invalid: ', err);
        this.destroyUserCredentials();
      }
    );
  }

  sendUsername(name: string) {
    this.username.next(name);
  }

  clearUsername() {
    this.username.next(undefined);
  }

  loadUserCredentials() {
    const credentials = JSON.parse(localStorage.getItem(this.tokenKey));

    if (credentials && credentials.username !== undefined) {
      this.useCredentials(credentials);
      if (this.authToken) {
        this.checkJWTtoken();
      }
    }
  }

  storeUserCredentials(credentials: any) {
    localStorage.setItem(this.tokenKey, JSON.stringify(credentials));
    this.useCredentials(credentials);
  }

  useCredentials(credentials: any) {
    this.isAuthenticated = true;
    this.sendUsername(credentials.username);
    this.authToken = credentials.token;
  }

  destroyUserCredentials() {
    this.authToken = undefined;
    this.clearUsername();
    this.isAuthenticated = false;
    localStorage.removeItem(this.tokenKey);
  }

  getUsers(): Observable<any> {
    return this.http
      .get(this.baseURL + 'users')
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  errorMessage = '';
  signUp(user: any): any {
    this.http
      .post(this.baseURL + 'users/signup', user, this.httpOptions)
      .subscribe(
        (response) => {
          console.log(response);
          this.logIn(user);
        },
        (error) => {
          this.showError();
          document.querySelector(
            '.alert2 strong'
          ).innerHTML = ` ${error.error.status}`;
          console.log(error);
        }
      );
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      methode: 'POST',
    }),
  };
  logIn(user: any): any {
    this.http
      .post<AuthResponse>(
        this.baseURL + 'users/login',
        { username: user.username, password: user.password },
        this.httpOptions
      )
      .subscribe(
        (res) => {
          this.storeUserCredentials({
            username: user.username,
            token: res.token,
          });
          window.location.reload();
        },
        (error) => {
          console.log(error);

          this.showError();
          document.querySelector(
            '.alert2 strong'
          ).innerHTML = ` ${error.error.err.message}`;
        }
      );

    // .pipe(
    //   map((res) => {

    //     this.storeUserCredentials({
    //       username: user.username,
    //       token: res.token,
    //     });
    //     console.log('res', res);

    //     return { success: true, username: user.username };
    //   }),
    //   catchError((error) => this.processHTTPMsgService.handleError(error))
    // );
  }

  showError() {
    document.getElementById('alert').style.display = 'block';
    document.querySelector('#icon').classList.remove('bi-check-circle');
    document
      .querySelector('#icon')
      .classList.add('bi-exclamation-triangle-fill');
  }

  logOut() {
    this.destroyUserCredentials();
  }

  isLoggedIn(): Boolean {
    return this.isAuthenticated;
  }

  getUsername(): Observable<string> {
    return this.username.asObservable();
  }

  getToken(): string {
    return this.authToken;
  }

  deleteUser(id: string) {
    return this.http
      .delete(this.baseURL + 'users/' + id)
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      )
      .subscribe((data) => console.log('return: ', data));
  }
}

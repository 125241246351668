import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SignupgaurdService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  canActivate(): boolean {
    ///return this.auth.getUsername().pipe(map((appUser: any) => appUser.admin));
    if (!this.auth.isLoggedIn()) return true;
    this.router.navigate(['/home']);
    return false;
  }
}

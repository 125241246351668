<div class="text">
    <div class="alert2" id="alert">
        <span class="closebtn" onclick="this.parentElement.style.display='none';">
            &times;
        </span>
        <i class="bi " id="icon"></i>
        <strong></strong>
    </div>
</div>

<app-header></app-header>
<ngx-loading-bar [color]="'#fff'" [height]="'3px'"></ngx-loading-bar>
<router-outlet></router-outlet>
<app-footer></app-footer>